import React, { FC, useState, useCallback, useRef, useEffect } from 'react'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Nav from '../components/nav'
import useZzz from '../components/zzz'

import '../styles/index.css'

const Page = ({
  Component,
  pageProps,
  showNav
}) => {
  return showNav
    ? <Component {...pageProps} />
    : null
}

const Slate = [
  [241, 245, 249], // 100
  [226, 232, 240], // 200
  [203, 213, 225], // 300
  [148, 163, 184], // 400
  [100, 116, 139], // 500
  [71, 85, 105], // 600
  [51, 65, 85], // 700
  [30, 41, 59], // 800
  [15, 23, 42], // 900
  [2, 6, 23], // 950
]
const Red = [
  [254, 226, 226], // 100
  [254, 202, 202], // 200
  [252, 165, 165], // 300
  [248, 113, 113], // 400
  [239, 68, 68], // 500
  [220, 38, 38], // 600
  [185, 28, 28], // 700
  [153, 27, 27], // 800
  [127, 29, 29], // 900
  [69, 10, 10], // 950
]

const MyApp: FC<AppProps> = ({
  Component,
  pageProps
}) => {
  const [showNav, setShowNav] = useState(true)
  const [doNoise, setDoNoise] = useState(false)
  const { setVol, resume, audioState, left, ctx } = useZzz()
  const canvRef = useRef<HTMLCanvasElement>()

  useEffect(() => {
    const canvas = canvRef.current
    const context = canvas.getContext('2d', { alpha: false });

    function onResize() {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
      if (!doNoise)
        window.requestAnimationFrame(onAnimate)
    }

    function drawNoise(context: CanvasRenderingContext2D, width: number, height: number, runlen: number) {
      const data = context.createImageData(width, height)
      const timeSeg = 180 * 1000
      const pos = 1 - (Math.cos(((runlen % timeSeg) / timeSeg) * Math.PI * 2) * 0.5 + 0.5)

      for (let i = 0, len = data.data.buffer.byteLength; i < len; i += 4) {
        const idx = Math.floor(Math.random() * 10)
        const col = Math.random() + pos > 1.25 ? Red[idx] : Slate[idx]
        data.data[i] = col[0]
        data.data[i + 1] = col[1]
        data.data[i + 2] = col[2]
        data.data[i + 3] = 255 // force alpha to 100%

      }
      context.putImageData(data, 0, 0)
      // context.font = "48px serif";
      // context.fillText(pos.toString(), 10, 50);
    }

    function drawEllipse(isRight: boolean) {
      const hoff = canvas.height / 8
      const woff = canvas.width / 8

      const x = isRight
        ? (woff * 5) + (Math.random() * woff * 2)
        : (woff) + (Math.random() * woff * 2)
      const y = (hoff * 2) + Math.random() * hoff * 4
      const w = Math.random() * woff
      const h = Math.random() * hoff
      context.beginPath();
      context.ellipse(x, y, w, h, Math.random() * 2 * Math.PI, 0, 2 * Math.PI);
      context.stroke();
    }
    let done = !doNoise
    let start: number
    let max: number
    let elapsed: number
    let then: number
    const fps = 25
    const fpsInterval = 1000 / fps

    function onAnimate(timestamp: number) {

      if (start === undefined) {
        start = timestamp
        then = start
        max = 0
      }
      const len = 180 * 1000
      const runlen = timestamp - start
      elapsed = timestamp - then
      if (max === 0 || elapsed > fpsInterval) {
        then = timestamp - (elapsed % fpsInterval);
        // runlen comes in as millisecons
        const mul = 1 - (Math.cos(((runlen % len) / len) * Math.PI * 2) * 0.5 + 0.5)
        max = Math.max(mul, max)

        drawNoise(context, canvas.width, canvas.height, timestamp - start)
        if (doNoise) {
          context.strokeStyle = Math.random() > 0.5 ? `rgba(255, 0, 0, ${max})` : `rgba(127, 127, 127, ${max})`
          context.lineWidth = 2 + Math.random() * 6
          drawEllipse(false)
          drawEllipse(true)
        }
      }
      if (!done)
        window.requestAnimationFrame(onAnimate)
    }
    window.addEventListener('resize', onResize)
    onResize()
    window.requestAnimationFrame(onAnimate)
    return () => {
      window.removeEventListener('resize', onResize)
      done = true
    }
  }, [doNoise])
  const toggle = useCallback(() => resume()
    .then((astate) => setShowNav(sn => {
      const play = sn && astate === 'running'
      if (play) {
        setDoNoise(true)
        if (left && ctx) {
          left.frequency.setValueAtTime(220, ctx.currentTime)
          left.frequency.linearRampToValueAtTime(210, ctx.currentTime + 3)
          left.frequency.setValueAtTime(210, ctx.currentTime + 3.0001)
          left.frequency.linearRampToValueAtTime(205, ctx.currentTime + 13)
          left.frequency.setValueAtTime(205, ctx.currentTime + 13.0001)
          left.frequency.linearRampToValueAtTime(225, ctx.currentTime + 60)
          left.frequency.setValueAtTime(225, ctx.currentTime + 60.0001)
          left.frequency.linearRampToValueAtTime(210, ctx.currentTime + 180)
          left.frequency.setValueAtTime(225, ctx.currentTime + 180.0001)
          left.frequency.linearRampToValueAtTime(220, ctx.currentTime + 360)
        }
        setVol(0.5)
      } else {
        setDoNoise(false)
        setVol(0.0)
      }
      return !sn
    }))
    .catch(console.error)
    , [resume, setVol, left, ctx])


  return (
    <>
      <Head>
        <link rel="icon" href="https://assets.august.black/img/noise64.png" type="image/png" />
        <title>august black</title>
        <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
        <meta httpEquiv="content-language" content="EN" />
        <meta name="Author" content="August Black" />
        <meta name="Publisher" content="August Black" />
        <meta name="Keywords" content="August Black, black august, Augusto Nero, Augusto Negro, Gustl Schwarz,
          la radia, mezcal, userradio, free software, open source, gnu, culture, art, kunst, whatever, augmented space,
          augmentus, augment, radio, radio art, software, pure data, datadada," />
        <meta name="description" content="the black august of time and space" />
        <meta name="Abstract" content="This is the online content of August Black, great lover of art
          and poetry." />
        <meta name="Copyright" content="All contents Copyright © August Black 1999-2021." />
        <meta name="Distribution" content="global" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <canvas ref={canvRef} className="fixed left-0 top-0 -z-10" />
      <div className="p0 lg:p-10 xl:p-12">
        <Nav toggle={toggle} show={showNav} canPlayAudio={audioState === 'running'} />
        <Page Component={Component} pageProps={pageProps} showNav={showNav} />
      </div>

    </>
  )
}

export default MyApp
